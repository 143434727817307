import React, {useState} from 'react'
import MusicPlayer from './MusicPlayer'
import { motion } from "framer-motion";
import VisibilitySensor from "react-visibility-sensor";
const Search = () => {
    const [elementIsVisible, setElementIsVisible] = useState(false);
    const bg = {
      true: {
        left: "-44rem",
      },
      false: {
        left: "-50rem",
      },
    };
    const redimg = {
      true: {
        left: "18rem",
      },
      false: {
        left: "16rem",
      },
    };
    const musicimg = {
      true: {
        left: "2rem",
      },
      false: {
        left: "6rem",
      },
    };
  return (
   <div className="search relative h-[65rem] px-[5rem] bg-[#081730]
   pt-[18rem] pb-[10rem] mt-[-15rem] z-[1] flex items-center 
   justify-between rounded-b-[5rem]" >
     <div className="left flex-1">
         <motion.img
           variants={bg}
           animate={`${elementIsVisible}`}
           transition={{
             duration: 1,
             type: "ease-out",
           }}
          src={require('../img/backgraphics.png')}
         className="absolute top-[22rem] left-[-47rem]" alt="back" />
         <motion.img

          src={require('../img/d1.png')} alt="p1"
         className="absolute w-[16rem] top-[26rem]" />
         <motion.img src={require('../img/d2.png')} alt="p2"
         className="absolute w-[9rem] top-[32.7rem] left-[7rem]" />
         <motion.img
           variants={redimg}
           animate={`${elementIsVisible}`}
           transition={{
             duration: 1.2,
             type: "ease-out",
           }}
          src={require('../img/d3.png')} alt="p3"
         className="absolute w-[9rem] top-[33rem] left-[17rem]" />
          <motion.img 
           variants={musicimg}
           animate={`${elementIsVisible}`}
           transition={{
             duration: 1,
             type: "ease-out",
           }}
          src={require('../img/d4.png')} alt="p4"
         className="absolute w-[17rem] top-[50rem] left-[2rem]" />
       
     </div>
     <div className="right flex flex-col items-start justify-start flex-1
      h-[100%] pt-[9rem] ">
    
            <div className="searchbar flex justify-start w-[100%] ">
                <input type="text" placeholder="Enter the URL ... " 
                className='flex-[19] outline-none bg-[#020917] rounded-xl
                p-3 h-[3rem] '/>
                <div className="searchIcon flex flex-1 items-center rounded-xl
                ml-4 bg-gradient-to-bl from-[#F3071D] to-[#E600FF] p-4 h-[3rem]">
                    <img src={require('../img/search.png')}
                    className="w-[1.5rem]" alt="" />
                </div>
            </div>

            <div className="tild flex justify-start mt-7 items-center w-[100%]">
                <img src={require('../img/Path 318.png')} alt="path"
                className='w-[5rem]' />
            </div>
            <div className="detail flex flex-col mt-5 text-4xl">
                <span>Search music by</span>
                <span><b>Name or Direct URL</b></span>
                <span className="text-sm mt-3 text-[#4D586A]">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.<br/>
                     Magni temporibus, porro praesentium nesciunt necessitatibus <br/>
                     suscipit sint dolorem? Qui in modi molestias quisquam.
                  

                </span>

            </div>

            <VisibilitySensor
          onChange={(isVisible) => setElementIsVisible(isVisible)}
        >
          <MusicPlayer />
        </VisibilitySensor>


     </div>
   </div>
  )
}

export default Search