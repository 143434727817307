
import './App.css';
import Download from './components/Download';
import Experience from './components/Experience';
import Footer from './components/Footer';
import Header from './components/Header';
import Land from './components/Land';
import Search from './components/Search';

function App() {
  return (
    <div className="App text-white overflow-hidden">
     <Header/>
     <Land/>
     <Experience/>
     <Search/>
     <Download/>
     <Footer/>
    </div>
  );
}

export default App;
