import React from 'react'

const DownloadAds = () => {
    const downloadImg = 'border-[2px] border-[#232A4E] rounded-[13px] h-[3rem] w-[10rem]'
  return (
    <div className="download">
        <div className="downlaod-images flex">
            <img src={require("../img/App Store.png")} alt="app store" 
            className={downloadImg + ` mr-[5rem]`}/>
            <img src={require("../img/Google Play.png")} alt="Google Play" 
             className={downloadImg}/>
        </div>
    </div>
  )
}

export default DownloadAds